import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout.jsx';
import RichContent from '../components/rich-content.jsx';

export default function PrivacyCookiePolicy(props) {
	let { data } = props;
	let { sanityPrivacyCookiesPolicyPage } = data;
	let { _rawPrivacyContent, _rawCookiesContent, seo } = sanityPrivacyCookiesPolicyPage;

	return (
		<Layout seo={seo}>
			<article id="privacy" className="pt-32 prose xl:prose-lg max-w-none">
				<RichContent blocks={_rawPrivacyContent} />
			</article>

			<article id="cookies" className="py-32 prose xl:prose-lg max-w-none">
				<RichContent blocks={_rawCookiesContent} />
			</article>
		</Layout>
	);
}

export const query = graphql`
	query($language: String) {
		sanityPrivacyCookiesPolicyPage(lang: { eq: $language }) {
			_rawPrivacyContent(resolveReferences: { maxDepth: 5 })
			_rawCookiesContent(resolveReferences: { maxDepth: 5 })
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
`;
